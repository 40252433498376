var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-companies-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] },"single-expand":"","expanded":_vm.state.expanded},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:expanded":function($event){return _vm.$set(_vm.state, "expanded", $event)},"click:row":function (_, ref) {
	var expand = ref.expand;
	var isExpanded = ref.isExpanded;

	return expand(!isExpanded);
}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"item.city",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.city))]}},{key:"item.stand",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._v(_vm._s(item.standWidth * item.standDepth)+" ㎡")]}},{key:"item.totalLeftToPay",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.totalServicePrice)?[(item.totalServicePrice > 0)?_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.totalServicePrice)+" "+_vm._s(_vm.state.currency))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.company.list.noDeposit')))])]):_vm._e(),(item.totalServicePrice == 0)?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.totalServicePrice)+" "+_vm._s(_vm.state.currency))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.company.list.paymentCleared')))])]):_vm._e(),(item.totalServicePrice < 0)?_c('v-tooltip',{attrs:{"top":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.totalServicePrice)+" "+_vm._s(_vm.state.currency))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('event.panel.company.list.excessPayment')))])]):_vm._e()]:_vm._e()]}},{key:"item.isActive",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.isActive)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.company.edit', params: { cid: item.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-message")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.company.messages.news')))])],1)]}}],null,true),model:{value:(item.messageDialog),callback:function ($$v) {_vm.$set(item, "messageDialog", $$v)},expression:"item.messageDialog"}},[_c('o-company-message',{attrs:{"companyId":item.id},on:{"close":function($event){item.messageDialog = false}}})],1),_c('v-dialog',{attrs:{"max-width":"1100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-at")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.sendEmail')))])],1)]}}],null,true),model:{value:(item.mailDialog),callback:function ($$v) {_vm.$set(item, "mailDialog", $$v)},expression:"item.mailDialog"}},[_c('m-send-email',{attrs:{"item":item},on:{"close":function($event){item.mailDialog = false}}})],1),_c('v-menu',{attrs:{"left":"","offset-x":true,"open-on-click":false,"open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-list-status")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.acceptReject')))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.acceptOrReject(true, item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.accept')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.acceptOrReject(false, item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('panel.abstract.list.actions.reject')))])],1)],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('m-company-list-item',{attrs:{"company":item,"currency":_vm.state.currency,"elementWidth":headers.length}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }