



















































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    OCompanyMessage: () =>
      import("@/components/organisms/event/company/o-company-message.vue"),
    MCompanyListItem: () =>
      import("@/components/molecules/company/m-company-list-item.vue"),
    MSendEmail: () => import("@/components/molecules/company/m-send-email.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      expanded: [],
      headers: [
        { value: "name", text: "Nazwa" },
        { value: "city", text: "Miasto", align: "right", sortable: false },
        { value: "stand", text: "Stoisko", align: "right", sortable: false },
        {
          value: "totalLeftToPay",
          text: "Do zapłaty",
          align: "right",
          sortable: false,
        },
        {
          value: "isActive",
          text: "Status",
          align: "right",
          sortable: false,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      currency: "",
      error: false as boolean | number,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/company`, {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { companies, total } }) => {
          state.empty = false;
          state.items = companies.map((company: any) => ({
            name: "",
            ...company,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    const acceptOrReject = (isAccepted: boolean, id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isAccepted,
      };

      state.loading = true;

      axiosInstance
        .put(`company/${id}/accept`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.company.message.success"),
          });
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { state, fetchData, hasAccessTo, acceptOrReject };
  },
});
